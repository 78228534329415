import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/mode/smarty/smarty'
import 'codemirror/mode/css/css'
import "@codemirror/lint";

import 'dsmorse-gridster'
import '../lib/jquery.key'
import 'jstree'
import 'mathjs'

global.CodeMirror = CodeMirror
 